<template>
  <div class="login">
    <div class="Log_platform">
      <div class="logo">
        <!-- <img @click="goIndex" :src="loginDetail.logoImg" alt="" /> -->
        <svg class="icon logo-black" aria-hidden="true" style="width:2em">
          <use xlink:href="#icon-sjmjlogo"></use>
        </svg>
      </div>
      <div class="account">
        <img :src="loginDetail.accountImg" alt="" />
        <input @keyup.enter="login" type="text" v-model="phone" placeholder="请输入手机号码" />
      </div>
      <div class="password">
        <img :src="loginDetail.passwordImg" alt="" />
        <input @keyup.enter="login" type="password" v-model="password" placeholder="请输入密码" />
      </div>
      <div class="code">
        <div class="auth_code">
          <img :src="loginDetail.passwordImg" alt="" />
          <input @keyup.enter="login" type="text" v-model="code" placeholder="请输入验证码" />
        </div>
        <div class="code_num" @click="updateCode">
          {{ codeNum }}
        </div>
      </div>
      <div class="remember_password">
        <el-checkbox @change="rememberAccount" v-model="checked">记住账号</el-checkbox>
      </div>
      <div class="the_login">
        <el-button type="primary" :loading="logInTo" @click="login">立即登录</el-button>
      </div>
      <div class="forgot_password">
        <span @click="goForgotPassword">忘记密码</span>
        <div>
          <span>还没有账号？</span>
          <span class="registered" @click="goReistered">马上注册></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dump from "@/util/dump";
  export default {
    data() {
      return {
        phone: "", //手机号
        password: "", //密码
        code: "", //验证码
        codeNum: "0000", //随机验证码
        checked: false, //记住密码是否确定
        logInTo: false, //登陆按钮状态
        loginDetail: {
          logoImg: require("@/../public/icon/login/logo.png"),
          accountImg: require("@/../public/icon/login/account.jpg"),
          passwordImg: require("@/../public/icon/login/pwd.jpg"),
        },
        pathUrl:''
      };
    },
    beforeRouteEnter(to,from,next){
      next(n=>{
        n.pathUrl = from.fullPath
      })
    },
    created() {
      this.updateCode();
      this.initData();
    },
    route: {
      data() {
        this.$root.showLoading();
        return this.fetchCertificates().then((res) => {
          this.$root.dismissLoading();
          if (res.error) return this.$root.toastError(res.error);
          if (res.data.certificates.length > 0) {
            return res.data;
          } else {
            console.log(this.$route, "----当前页面的url信息----");
          }
        });
      },
      canActivate(transition) {
        console.log(transition, "======上一个页面的url信息=======");
        transition.next();
      }
    },
    methods: {
      //更新验证码
      updateCode() {
        const that = this;
        that.codeNum = "";
        for (let i = 0; i < 4; i++) {
          let num = Math.floor(Math.random() * 10);
          that.codeNum += num;
        }
      },
      //登录
      login() {
        const that = this;
        if (!that.phone) {
          that.$message({
            message: "手机号不能为空！",
            type: "warning",
          });
          return false;
        }
        if (!that.password) {
          that.$message({
            message: "密码不能为空！",
            type: "warning",
          });
          return false;
        }
        if (!that.code) {
          that.$message({
            message: "验证码不能为空！",
            type: "warning",
          });
          return false;
        }
        if (that.code != that.codeNum) {
          that.$message({
            message: "验证码不正确！",
            type: "warning",
          });
          that.updateCode();
          return false;
        }
        that.logInTo = true;
        that.$api
          .login({
            phone: that.phone,
            password: that.password
          })
          .then((res) => {
            if (res.status == 100) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("role_id", res.data.user.role_id);
              localStorage.setItem("name", res.data.user.name);
              localStorage.setItem("cityname", res.data.user.city_name);
              localStorage.setItem("city", res.data.user.city_id);
              localStorage.setItem("outTime", res.data.user.brand_end_date);
              localStorage.setItem("phone", res.data.user.phone);
              localStorage.setItem("vipId", res.data.vipId);
              localStorage.setItem("userAvatar", res.data.user.head_photo);
              sessionStorage.setItem("phone", res.data.user.phone);

              let role_id = res.data.user.role_id;
              if (
                role_id == "38e045588e0e4ce19b80ca40f5325934" ||
                role_id == "de9de2f006e145a29d52dfadda295353" ||
                role_id == "4"
              ) {
                that.checkboxSet(res.data.token);
              } else {
                that.loginJump();
              }
            } else {
              that.$message({
                message: res.msg,
                type: "error",
              });
              that.logInTo = false;
            }
          });
      },
      //设置价格开关
      checkboxSet(token) {
        const that = this;
        that.$api.checkboxSet({
          priceChange: "0",
          token: token
        }).then((res) => {
          if (res.status == 100) {
            that.loginJump();
          }
        });
      },
      //跳转
      loginJump() {
        const that = this;
        if (that.$route.query.id == 1 || that.pathUrl == '/') {
          setTimeout(()=>{
            dump.link({
            type: 1,
            link: "/",
          });
          that.logInTo = false;
          },1000)
        } else {
          setTimeout(()=>{
            that.$router.back();
            that.logInTo = false;
          },1000)
        }
      },
      //设置Cookies
      setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
      },
      //获取Cookies
      getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i].trim();
          if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
      },
      //记住密码
      rememberAccount(val) {
        const that = this;
        if (val) {
          that.setCookie("account", that.phone);
          that.setCookie("password", that.password);
        } else {
          that.setCookie("account", "");
          that.setCookie("password", "");
        }
      },
      //初始化数据
      initData() {
        const that = this;
        let account = that.getCookie("account");
        let password = that.getCookie("password");
        if (account || password) {
          that.checked = true;
          that.phone = account;
          that.password = password;
        }
      },
      //去马上注册
      goReistered() {
        this.$router.push("/registered");
      },
      //去忘记密码
      goForgotPassword() {
        this.$router.push("/forgotPassword");
      },
      //去首页
      goIndex() {
        this.$router.push("/");
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/view/login/login";
</style>
